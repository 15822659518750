import { LobId } from '~/type/lob'

export default defineNuxtRouteMiddleware(() => {
  const authStore = useAuth()

  const mappedLob = [
    {
      id: LobId.KMB_ID,
      prefix: '/kmb',
    },
    {
      id: LobId.KMOB_ID,
      prefix: '/kmob',
    },
    {
      id: LobId.WG_ID,
      prefix: '/nwg',
    },
    {
      id: LobId.UC_ID,
      prefix: '/uc',
    },
  ]

  const prefix = mappedLob.find(item => item.id === authStore.activeLob)?.prefix
  return navigateTo(`${prefix}/dashboard`)
})
